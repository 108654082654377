import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const StyledImg = styled(Img)`
  width: 100%;
  position: absolute !important;
  &:nth-child(1) {
    max-width: 1200px;
    max-height: 354px;
    top: 30%;
    @media only screen and (orientation: portrait) {
      height: 100vw;
      top: 55%;
    }
  }
  &:nth-child(2) {
    max-width: 674px;
    max-height: 314px;
    top: 40%;
    @media only screen and (orientation: portrait) {
      height: 50vw;
      top: 70%;
    }
  }
  &:nth-child(3) {
    max-width: 428px;
    max-height: 226px;
    top: 50%;
    left: 50%;

    @media only screen and (orientation: portrait) {
      top: 70%;
      width: 50vw;
    }
  }
`
const HeaderClouds = () => {
  const data = useStaticQuery(graphql`
    {
      cloudBig: file(name: { eq: "l" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      cloudMedium: file(name: { eq: "m" }) {
        childImageSharp {
          fluid(maxWidth: 674, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      cloudSmall: file(name: { eq: "s" }) {
        childImageSharp {
          fluid(maxWidth: 428, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <StyledImg
        fluid={data.cloudBig.childImageSharp.fluid}
        className="cloud-big"
      />
      <StyledImg
        fluid={data.cloudMedium.childImageSharp.fluid}
        className="cloud-medium"
      />
      <StyledImg
        fluid={data.cloudSmall.childImageSharp.fluid}
        className="cloud-small"
      />
    </Wrapper>
  )
}

export default HeaderClouds
