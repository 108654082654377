import React, { useEffect } from "react"
import Confetti from "react-dom-confetti"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Grid, Typography } from "@material-ui/core"
import { useIntl } from "gatsby-plugin-intl"

const Wrapper = styled.div`
  margin-bottom: 50px;
  @media only screen and (min-width: 1024px) {
    margin-bottom: 150px;
  }
`
const StyledTypography = styled(Typography)`
  position: relative;
  text-align: center;
  margin: 40px auto;
  font-size: 1rem;
  @media only screen and (min-width: 1024px) {
    font-size: 1.25rem;
  }
  span {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-150%, -50%);
    font-size: 30px;
    @media only screen and (min-width: 1024px) {
      font-size: 40px;
    }
  }
  span:nth-of-type(2) {
    left: auto;
    right: 0;
    transform: translate(150%, -50%);
  }
`

const Achievements = props => {
  const { isConfetti } = props
  const { formatMessage } = useIntl()
  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const wrapper = document.querySelectorAll(".achievements-wrapper")

    gsap.utils.toArray(wrapper).forEach(section => {
      gsap.from(section.querySelectorAll(".achievements-txt"), {
        scrollTrigger: {
          trigger: section,
          start: "top 50%",
        },
        autoAlpha: 0,
        y: 20,
        rotateX: 90,
        duration: 0.35,
        stagger: 0.15,
      })
    })
  }, [])

  return (
    <Wrapper className="achievements-wrapper">
      <Grid container justify="space-between">
        <Confetti active={isConfetti} config={config} />
        <Confetti active={isConfetti} config={config} />
      </Grid>

      <div
        style={{ width: "fit-content", margin: "0 auto", padding: "0 80px" }}
      >
        <StyledTypography variant="h6" className="achievements-txt">
          <span role="img" aria-label="Trophy">
            🏆
          </span>
          {formatMessage({ id: "Polish Hot Air Balloon Champions" })}{" "}
          <span role="img" aria-label="Trophy">
            🏆
          </span>
        </StyledTypography>
        <StyledTypography variant="h6" className="achievements-txt">
          <span role="img" aria-label="Trophy">
            🏆
          </span>
          {formatMessage({ id: "2nd Place in Junior World Championship" })}{" "}
          <span role="img" aria-label="Trophy">
            🏆
          </span>
        </StyledTypography>
        <StyledTypography variant="h6" className="achievements-txt">
          <span role="img" aria-label="Trophy">
            🏆
          </span>
          {formatMessage({
            id:
              "Multiple winners of the Polish Championship and The Polish Cup",
          })}{" "}
          <span role="img" aria-label="Trophy">
            🏆
          </span>
        </StyledTypography>
        <StyledTypography variant="h6" className="achievements-txt">
          <span role="img" aria-label="Trophy">
            🏆
          </span>
          {formatMessage({ id: "Representatives of the Polish National Team" })}{" "}
          <span role="img" aria-label="Trophy">
            🏆
          </span>
        </StyledTypography>
      </div>
    </Wrapper>
  )
}

export default Achievements
