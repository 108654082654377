import React, { useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const Wrapper = styled.section`
  padding: 7vw 7vw 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const IMG = styled(Image)`
  margin: 1%;
  &:nth-child(2),
  &:nth-child(3) {
    width: 95%;
    height: 400px;

    @media only screen and (min-width: 1024px) {
      width: 48%;
    }
  }

  &:nth-child(1),
  &:nth-child(4) {
    width: 96%;
    height: 40vw;
    max-height: 800px;

    @media only screen and (min-width: 1024px) {
      width: 98%;
    }
  }
`
const Gallery = () => {
  const data = useStaticQuery(graphql`
    {
      image1: file(name: { eq: "balloons-over-dubai" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image2: file(name: { eq: "gallery2" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image3: file(name: { eq: "gallery3" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      image5: file(name: { eq: "gallery5" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const images = document.querySelectorAll(".gallery-image")

    images.forEach(image => {
      gsap.fromTo(
        image,
        { y: "+=100", opacity: 0 },
        {
          y: 0,
          opacity: 1,
          stagger: 0.2,
          duration: 1,
          ease: "easeInOut",
          scrollTrigger: {
            trigger: image,
            start: "-10% 50%",
          },
        }
      )
    })
  }, [])
  return (
    <Wrapper>
      <IMG
        className="gallery-image"
        fluid={data.image1.childImageSharp.fluid}
      />
      <IMG
        className="gallery-image"
        fluid={data.image3.childImageSharp.fluid}
      />
      <IMG
        className="gallery-image"
        fluid={data.image2.childImageSharp.fluid}
      />
      <IMG
        className="gallery-image"
        fluid={data.image5.childImageSharp.fluid}
      />
    </Wrapper>
  )
}

export default Gallery
