import { Typography } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons"

const Wrapper = styled.div`
  position: relative;
  width: 90%;
  padding: 10px;
  margin: 30px auto;
  @media only screen and (min-width: 1024px) {
    width: 75%;
    padding: 20px;
    margin: 60px auto;
  }
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  position: absolute;
  top: -25px;
  left: 90%;
  @media only screen and (min-width: 1024px) {
    top: -5%;
    left: 100%;
  }
`
const StyledTypography = styled(Typography)`
  font-size: 14px;
  text-align: left;
  letter-spacing: 1px;
`
const StyledName = styled(Typography)`
  margin-top: 20px;
  text-align: left;
  font-size: 14px;
  color: #5a5a5a;
  span {
    color: #5a5a5a;

    font-weight: 600;
    font-size: 14px;
  }
`

const ClientOpinionCard = props => {
  return (
    <Wrapper>
      <StyledFontAwesomeIcon icon={faQuoteRight} />
      <StyledTypography variant="subtitle2" component="p">
        {props.children}
      </StyledTypography>
      <StyledName variant="subtitle2" component="p">
        <span>{props.name}</span> - {props.place}
      </StyledName>
    </Wrapper>
  )
}

export default ClientOpinionCard
