import React, { useEffect } from "react"
import styled from "styled-components"
import OfferCard from "../components/OfferCard/OfferCard"
import offer1 from "../assets/images/offer-1.png"
import offer2 from "../assets/images/offer-2.png"
import offer3 from "../assets/images/offer-3.png"
import offer5 from "../assets/images/offer-5.png"
import { Grid } from "@material-ui/core"
import { SectionTitle } from "../components/SectionTitle/SectionTitle"
import { SectionSubtitle } from "../components/SectionSubtitle/SectionSubtitle"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

const dataTable = [
  {
    img: offer1,
    title: <FormattedMessage id="Touristic flights" />,
    subtitle: <FormattedMessage id="Touristic flights description" />,
    link: "offer/flights",
  },
  {
    img: offer2,
    title: <FormattedMessage id="Advertising banners" />,
    subtitle: <FormattedMessage id="Advertising banners description" />,
    link: "offer/banners",
  },
  {
    img: offer3,
    title: <FormattedMessage id="Skydiving" />,
    subtitle: <FormattedMessage id="Skydiving description" />,
    link: "offer/skydiving",
  },
  {
    img: offer5,
    title: <FormattedMessage id="Balloon sales" />,
    subtitle: <FormattedMessage id="Balloon sales description" />,
    link: "offer/selling",
  },
]

const StyledSpan = styled.span`
  transform: rotate(4deg);
  background-color: ${({ theme }) => theme.colors.grayBlue};
  position: absolute;
  height: 100%;
  width: 200%;
  left: -50%;
  top: 20px;
  z-index: -1;
`

const Offer = () => {
  const { formatMessage } = useIntl()
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const wrapper = document.querySelectorAll(".offer-cards-wrapper")

    gsap.utils.toArray(wrapper).forEach(section => {
      gsap.fromTo(
        section.querySelectorAll(".offer-card"),
        { autoAlpha: 0, x: 25 },
        {
          scrollTrigger: {
            trigger: section,
            start: "-30% 50%",
          },
          autoAlpha: 1,
          x: 0,
          duration: 0.55,
          stagger: 0.25,
        }
      )
    })
  }, [])

  return (
    <section
      style={{
        position: "relative",
        width: "100vw",
        overflow: "hidden",
        padding: "100px 0 100px",
      }}
    >
      <StyledSpan />
      <SectionTitle>
        {formatMessage({ id: "What we can offer you" })}
      </SectionTitle>
      <SectionSubtitle>
        <FormattedMessage
          id="Offer subtitle"
          values={{
            strong: chunks => <strong>{chunks}</strong>,
          }}
        />
      </SectionSubtitle>
      <Grid
        item
        xs={12}
        style={{ margin: "0 auto", maxWidth: "1500px" }}
        container
        justify="space-around"
        alignItems="center"
        className="offer-cards-wrapper"
      >
        {dataTable.map(({ img, title, subtitle, link }) => (
          <OfferCard
            className="offer-card"
            key={title}
            img={img}
            title={title}
            subtitle={subtitle}
            link={link}
          />
        ))}
      </Grid>
    </section>
  )
}

export default Offer
