import React from "react"
import { injectIntl, useIntl } from "gatsby-plugin-intl"
import Hero from "../views/Hero"
import Offer from "../views/Offer"
import About from "../views/About"
import { Helmet } from "react-helmet"
import Navigation from "../components/Navigation/Navigation"
import Footer from "../views/Footer"

const Home = () => {
  const { formatMessage, locale } = useIntl()

  const balloonFlights = formatMessage({ id: "Balloon Flights" })
  return (
    <>
      <Helmet htmlAttributes={{ lang: locale }}>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
     user-scalable=0"
        />
        <meta name="robots" content="index, follow" />
        <title data-react-helmet="true">
          {`${balloonFlights} | Skywalkers Balloon Club`}
        </title>
        <meta
          name="description"
          data-react-helmet="true"
          content={formatMessage({ id: "metaDescription" })}
        />
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://www.skywalkers.com"
        />
        <meta data-react-helmet="true" property="og:type" content="article" />
        <meta
          data-react-helmet="true"
          property="og:title"
          content={`${balloonFlights} | Skywalkers Balloon Club`}
        />
        <meta
          data-react-helmet="true"
          property="og:description"
          content={formatMessage({ id: "metaDescription" })}
        />
        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/skywalkers-1362f.appspot.com/o/homepage.jpg?alt=media&token=bde9c4f3-3638-4f5a-8e32-6b10a1bcd834"
        />
        <link
          rel="icon"
          href="https://firebasestorage.googleapis.com/v0/b/skywalkers-1362f.appspot.com/o/icon-48x48.png?alt=media&token=2bd99e6d-f7df-4459-8736-0139be12b647"
        />
        <link rel="manifest" href="/manifest.webmanifest" />
        <meta name="theme-color" content="#020c1b" />
        <link
          rel="apple-touch-icon"
          sizes="48x48"
          href="https://firebasestorage.googleapis.com/v0/b/skywalkers-1362f.appspot.com/o/icon-48x48.png?alt=media&token=2bd99e6d-f7df-4459-8736-0139be12b647"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="https://firebasestorage.googleapis.com/v0/b/skywalkers-1362f.appspot.com/o/icon-72x72.png?alt=media&token=700e4475-62e1-4c14-84fd-8b50a51ca1d4"
        />
        <link
          rel="apple-touch-icon"
          sizes="96x96"
          href="https://firebasestorage.googleapis.com/v0/b/skywalkers-1362f.appspot.com/o/icon-96x96.png?alt=media&token=8275c4e1-a806-42fd-8aa9-6c771d43173c"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="https://firebasestorage.googleapis.com/v0/b/skywalkers-1362f.appspot.com/o/icon-144x144.png?alt=media&token=3156fb04-6ea3-4007-a82d-fb8b179e5bdf"
        />
        <link
          rel="apple-touch-icon"
          sizes="192x192"
          href="https://firebasestorage.googleapis.com/v0/b/skywalkers-1362f.appspot.com/o/icon-192x192.png?alt=media&token=702850a7-8268-44e0-a46d-72a282e7dcdb"
        />
        <link
          rel="apple-touch-icon"
          sizes="256x256"
          href="https://firebasestorage.googleapis.com/v0/b/skywalkers-1362f.appspot.com/o/icon-256x256.png?alt=media&token=1296c72d-2eb7-4642-b48a-6df9ea6cb983"
        />
        <link
          rel="apple-touch-icon"
          sizes="384x384"
          href="https://firebasestorage.googleapis.com/v0/b/skywalkers-1362f.appspot.com/o/icon-384x384.png?alt=media&token=7c77d5d8-108e-4258-9208-4f52e09dcac1"
        />
        <link
          rel="apple-touch-icon"
          sizes="512x512"
          href="https://firebasestorage.googleapis.com/v0/b/skywalkers-1362f.appspot.com/o/icon-512x512.png?alt=media&token=4f176853-258c-4f3b-8574-5400677e0dcc"
        />
      </Helmet>
      <Navigation />
      <Hero />
      <Offer />
      <About />
      <Footer />
    </>
  )
}

export default injectIntl(Home)
