import React, { useEffect, useState, useCallback } from "react"
import styled from "styled-components"
import { FormattedMessage } from "gatsby-plugin-intl"

import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { ButtonPrimary } from "../components/ButtonPrimary/ButtonPrimary"
import { Typography, Grid } from "@material-ui/core"
import { enterHero } from "../animations/enterAnimations"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import ArrangeDialog from "../components/ArrangeDialog/ArrangeDialog"
import HeaderClouds from "../components/HeaderClouds/HeaderClouds"
import InformationDialog from "../components/ArrangeDialog/InformationDialog/InformationDialog"
import AfterSendEmailDialog from "../components/ArrangeDialog/AfterSendEmailDialog/AfterSendEmailDialog"
import SocialMedia from "../components/SocialMedia/SocialMedia"
import { gsap, Power1 } from "gsap"
import { useIntl } from "react-intl"

const Wrapper = styled.section`
  position: relative;
`

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
  overflow-x: hidden;
`
const HeroWrapper = styled.div`
  position: relative;
  left: -20%;
  top: 50px;
  @media screen and (min-width: 1024px) {
    top: 3vh;
  }

  .mask {
    &.mask1 {
      left: 7%;
    }
    margin-left: 3px;
    width: 70vw;
    @media screen and (min-width: 1024px) {
      width: 90vh;
    }
    @media screen and (min-width: 1920px) {
      width: 850px;
    }
  }
  div.mask2 {
    position: absolute !important;
    top: 0;
    left: 0;
  }
  h1 {
    position: absolute;
    font-size: 20vw;
    font-family: ${({ theme }) => theme.fonts.sansSerif};
    left: 13%;
    top: -13%;
    line-height: 0.9;
    @media screen and (min-width: 1024px) {
      font-size: 25vh;
    }
    @media screen and (min-width: 1920px) {
      font-size: 236.25px;
    }
    span {
      font-family: ${({ theme }) => theme.fonts.sanSerif};
    }
    span.big {
      display: inline-block;
      visibility: hidden;
    }
    span.small {
      display: inline-block;
      font-size: 8vw;
      visibility: hidden;
      @media screen and (min-width: 1024px) {
        font-size: 8vh;
      }
      @media screen and (min-width: 1920px) {
        font-size: 75.6px;
      }
    }
    span.hidden {
      opacity: 1;
      margin: 0 0.6vw;
    }
  }
`

const StyledTypography = styled(Typography)`
  visibility: hidden;
  width: 90%;
  max-width: 650px;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.2rem;
  margin: 70px 0 30px;

  &.MuiTypography-subtitle2 {
    margin-top: 10px;
    font-size: 0.7rem;
  }

  @media only screen and (min-width: 1024px) {
    margin-top: 1vh;
    font-size: 1.6rem;
    &.MuiTypography-subtitle2 {
      font-size: 0.875rem;
    }
  }
`

const Line = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  height: 1px;
  background-color: #383838;
  width: 100vh;
  visibility: hidden;
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
`
const Header = () => {
  const [arrangeDialogOpen, setArrangeOpen] = useState(false)
  const [informationDialogOpen, setInformationOpen] = useState(false)
  const [emailSendDialogOpen, setEmailSendOpen] = useState(false)
  const [isSendError, setSendError] = useState(false)
  const [isMobile, setIsMobile] = useState()
  const { formatMessage, locale } = useIntl()

  const data = useStaticQuery(graphql`
    {
      mask: file(name: { eq: "hero-mask" }) {
        childImageSharp {
          fluid(maxWidth: 843, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const handleClose = () => {
    setArrangeOpen(false)
  }
  const handleCloseInformationDialog = () => {
    setInformationOpen(false)
  }
  const handleOpen = event => {
    event.preventDefault()
    setArrangeOpen(true)
    setInformationOpen(true)
  }

  useEffect(() => {
    enterHero().play()
    const cloudBig = document.querySelector(".cloud-big")

    const tl = gsap.timeline({
      yoyo: true,
      repeat: -1,
    })

    tl.to(cloudBig, {
      y: -25,
      duration: 8,
      delay: 1,
      ease: Power1.easeInOut,
      yoyo: Power1.easeInOut,
    })
    const cloudMedium = document.querySelector(".cloud-medium")

    const tlm = gsap.timeline({
      yoyo: true,
      repeat: -1,
    })

    tlm.to(cloudMedium, {
      y: -35,

      duration: 6,
      ease: Power1.easeInOut,
      yoyo: Power1.easeInOut,
    })

    const cloudSmall = document.querySelector(".cloud-small")

    const tls = gsap.timeline({
      yoyo: true,
      repeat: -1,
    })

    tls.to(cloudSmall, {
      y: -55,

      duration: 8,
      ease: Power1.easeInOut,
      yoyo: Power1.easeInOut,
    })

    setTimeout(() => {
      const mask1 = document.querySelector(".mask1")
      const tl = gsap.timeline({
        yoyo: true,
        repeat: -1,
      })

      tl.to(mask1, {
        y: -25,
        duration: 4,
        ease: Power1.easeInOut,
        yoyo: Power1.easeInOut,
      })
    }, 3000)
  }, [])

  const setView = useCallback(() => {
    if (window.innerWidth >= 1024) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, [])
  useEffect(() => {
    window.addEventListener("resize", setView)

    return () => {
      window.removeEventListener("resize", setView)
    }
  }, [setView])

  useEffect(() => {
    setView()
  }, [setView])

  return (
    <Wrapper>
      <HeaderClouds />
      <CenterWrapper className="hero-wrapper">
        {isMobile ? null : <SocialMedia />}
        <HeroWrapper>
          <h1>
            <span className="small fade-in-first">
              {formatMessage({ id: "Fly the" })}
            </span>
            <br />
            <span className="big fade-in-first">
              {formatMessage({ id: "Ball" })}
              <span className="hidden">o</span>
              {formatMessage({ id: "on" })}
            </span>
          </h1>
          <Image
            style={{ left: locale === "en" ? "7%" : "0" }}
            className="mask mask1"
            fluid={data.mask.childImageSharp.fluid}
          />
        </HeroWrapper>

        <Grid
          item
          container
          xs={10}
          style={{
            maxWidth: "900px",
            paddingBottom: "60px",
          }}
        >
          <Grid
            item
            container
            xs={12}
            md={9}
            direction="column"
            justify="center"
          >
            <Grid
              item
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <StyledTypography
                className="fade-in-second"
                variant="h4"
                gutterBottom
              >
                <FormattedMessage
                  id="heroTitle"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>,
                  }}
                />
              </StyledTypography>
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <StyledTypography
                className="fade-in-second"
                variant="subtitle2"
                gutterBottom
              >
                <FormattedMessage
                  id="heroSubtitle"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>,
                  }}
                />
              </StyledTypography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={3}
            alignItems="center"
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <ButtonPrimary
              className="main"
              variant="contained"
              size="large"
              endIcon={<ArrowRightAltIcon />}
              main="true"
              onClick={handleOpen}
            >
              {formatMessage({ id: "Book a flight" })}
            </ButtonPrimary>

            <Line className="button-line" />
          </Grid>
        </Grid>
      </CenterWrapper>

      <ArrangeDialog
        arrangeDialogOpen={arrangeDialogOpen}
        handleClose={handleClose}
        setEmailSendOpen={setEmailSendOpen}
        setSendError={setSendError}
      />
      <InformationDialog
        informationDialogOpen={informationDialogOpen}
        handleCloseInformationDialog={handleCloseInformationDialog}
        setInformationOpen={setInformationOpen}
      />
      <AfterSendEmailDialog
        setEmailSendOpen={setEmailSendOpen}
        emailSendDialogOpen={emailSendDialogOpen}
        isSendError={isSendError}
      />
    </Wrapper>
  )
}

export default Header
