import React, { useEffect, useCallback, useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { SectionTitle } from "../components/SectionTitle/SectionTitle"
import Gallery from "./Gallery"
import { SectionSubtitle } from "../components/SectionSubtitle/SectionSubtitle"
import { Divider, Grid, Typography } from "@material-ui/core"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import ClientOpinionCard from "../components/ClientOpinionCard/ClientOpinionCard"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Achievements from "../components/Achievements/Achievements"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

const StyledImage = styled(Image)`
  margin: 20px;
`

const StyledCarousel = styled(Carousel)`
  width: 95vw;
  max-width: 1000px;
  margin: 0 auto;
  li.slide {
    background: transparent;
  }

  .carousel .control-dots .dot {
    border-radius: 5px;
    width: 30px;
    height: 5px;
    opacity: 0.5;
    background-color: #fff;
  }
  .carousel .control-dots .dot.selected {
    background-color: #000;
  }

  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    opacity: 1;
  }

  .carousel .slider-wrapper.axis-horizontal .slider {
    align-items: center;
  }
`

const StyledTypography = styled(Typography)`
  position: absolute;
  font-size: 7rem;
  top: 0;
  left: 0;
  transform: translateX(-10%);
  color: #19191914;
  font-weight: 600;
  @media only screen and (min-width: 1024px) {
    font-size: 10rem;
  }
`

const StyledSection = styled.section`
  overflow: hidden;
  padding: 150px 0;
  position: relative;
`

const Description = styled(Typography)`
  max-width: 800px;
  margin-left: 5vw;
  font-weight: 100;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray};
  line-height: 1.9rem;
  width: 90%;
  margin-bottom: 40px;
  @media only screen and (min-width: 1024px) {
    width: 40vw;
    margin-bottom: 0;
  }
  b {
    color: ${({ theme }) => theme.colors.gray};
  }
`

const StyledOwnerImage = styled(Image)`
  width: 47%;
  max-height: 300px;
  max-width: 380px;
  margin: 1%;
  &:nth-child(3) {
    width: 96%;
    max-height: 500px;
    max-width: none;
  }
`

const StyledSectionTitle = styled(SectionTitle)`
  margin: 0 0 40px;
  margin-left: 5vw;

  @media only screen and (min-width: 1024px) {
    margin-top: -100px;
  }
`

const StyledGrid = styled(Grid)`
  position: relative;
  max-width: 1700px;
  margin: 0 auto 70px;

  @media only screen and (min-width: 1024px) {
    margin: 0 auto 200px;
  }
`

const About = () => {
  const data = useStaticQuery(graphql`
    {
      kbsWorld: file(name: { eq: "kbs-world" }) {
        childImageSharp {
          fixed(height: 30, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      tvp: file(name: { eq: "tvp" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      lot: file(name: { eq: "lot" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      us: file(name: { eq: "us" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      tvn: file(name: { eq: "tvn" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      ursa: file(name: { eq: "ursa" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      toyota: file(name: { eq: "toyota" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      witold: file(name: { eq: "witold" }) {
        childImageSharp {
          fluid(maxHeight: 1700, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      tomasz: file(name: { eq: "tomasz" }) {
        childImageSharp {
          fluid(maxHeight: 1700, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      owners: file(name: { eq: "owners" }) {
        childImageSharp {
          fluid(maxHeight: 1700, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const [isConfetti, setConfetti] = useState(false)
  const { formatMessage } = useIntl()
  const clientsData = [
    {
      imgFluid: data.kbsWorld.childImageSharp.fixed,
      name: "KBS-WORLD",
    },
    {
      imgFluid: data.tvp.childImageSharp.fixed,
      name: "Telewizja Polska",
    },
    {
      imgFluid: data.lot.childImageSharp.fixed,
      name: "Lot Polish Airlines",
    },
    {
      imgFluid: data.us.childImageSharp.fixed,
      name: "Uniwersytet Śląski",
    },
    {
      imgFluid: data.tvn.childImageSharp.fixed,
      name: "Telewizja TVN",
    },
    {
      imgFluid: data.ursa.childImageSharp.fixed,
      name: "URSA",
    },
    {
      imgFluid: data.toyota.childImageSharp.fixed,
      name: "Toyota",
    },
  ]

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const logoWrapper = document.querySelectorAll(".client-logo-wrapper")

    gsap.utils.toArray(logoWrapper).forEach(section => {
      gsap.from(section.querySelectorAll(".client-logo"), {
        scrollTrigger: {
          trigger: section,
          start: "top 50%",
        },
        autoAlpha: 0,
        y: 20,
        rotateX: 90,
        duration: 0.35,
        stagger: 0.15,
      })
    })
  }, [])
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const logoWrapper = document.querySelectorAll(".about-us-description")

    gsap.utils.toArray(logoWrapper).forEach(section => {
      gsap.from(section.querySelectorAll(".owner-image"), {
        scrollTrigger: {
          trigger: section,
          start: "top 50%",
        },
        autoAlpha: 0,
        scale: 0.75,
        x: 20,
        duration: 1,
        stagger: 0.15,
      })
    })
  }, [])
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const logoWrapper = document.querySelectorAll(".about-us-description")

    gsap.utils.toArray(logoWrapper).forEach(section => {
      gsap.from(section.querySelectorAll(".about-us-txt"), {
        scrollTrigger: {
          trigger: section,
          start: "top 50%",
        },
        autoAlpha: 0,
        y: 20,
        duration: 1,
        stagger: 0.15,
      })
    })
  }, [])

  const showConfetti = useCallback(() => {
    const achievementsTitle = document.querySelector(".achievements-title")

    if (achievementsTitle.getBoundingClientRect().top <= 300) {
      setConfetti(true)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", showConfetti)

    return () => {
      window.removeEventListener("scroll", showConfetti)
    }
  }, [showConfetti])

  return (
    <StyledSection className="about">
      <StyledTypography variant="h3">
        {formatMessage({ id: "About us" })}
      </StyledTypography>
      <StyledGrid
        container
        justify="space-between"
        alignItems="center"
        className="about-us-description"
      >
        <Grid item xs={12} md={6}>
          <StyledSectionTitle className="about-us-txt">
            {formatMessage({ id: "Why us" })}?
          </StyledSectionTitle>
          <Description variant="h6" component="p" className="about-us-txt">
            <FormattedMessage
              id="Why us 1"
              values={{
                strong: chunks => <strong>{chunks}</strong>,
              }}
            />
            <br />
            <FormattedMessage
              id="Why us 2"
              values={{
                strong: chunks => <strong>{chunks}</strong>,
              }}
            />
            <br />
            <FormattedMessage
              id="Why us 3"
              values={{
                strong: chunks => <strong>{chunks}</strong>,
              }}
            />
          </Description>
        </Grid>

        <Grid
          container
          item
          xs={11}
          md={6}
          alignItems="center"
          style={{ maxWidth: "700px", margin: " 0 auto" }}
        >
          <StyledOwnerImage
            fluid={data.witold.childImageSharp.fluid}
            className="owner-image"
          />
          <StyledOwnerImage
            fluid={data.tomasz.childImageSharp.fluid}
            className="owner-image"
          />
          <StyledOwnerImage
            fluid={data.owners.childImageSharp.fluid}
            className="owner-image"
          />
        </Grid>
      </StyledGrid>
      <SectionTitle
        small
        className="achievements-title"
        style={{ marginBottom: "20px" }}
      >
        {formatMessage({ id: "Achievements and Awards" })}
      </SectionTitle>
      <Achievements isConfetti={isConfetti} />
      <SectionSubtitle small style={{ margin: "100px 0 10px" }}>
        {formatMessage({ id: "What others say about us" })}
      </SectionSubtitle>
      <Divider
        light
        style={{
          backgroundColor: "rgb(0 0 0/ 0.1)",
          width: "50%",
          margin: "0 auto",
        }}
      />
      <SectionTitle small style={{ margin: "10px auto 20px" }}>
        {formatMessage({ id: "Opinons of our clients" })}
      </SectionTitle>

      <StyledCarousel
        infiniteLoop={true}
        autoPlay={true}
        showStatus={false}
        transitionTime={500}
        interval={5000}
        showThumbs={false}
        swipeable={false}
      >
        <ClientOpinionCard name="Agata Lewkowicz" place="Pilica">
          {formatMessage({ id: "Opinion1" })}
        </ClientOpinionCard>
        <ClientOpinionCard name="Dagmara Smoktunowicz" place="Wolbrom">
          {formatMessage({ id: "Opinion2" })}
        </ClientOpinionCard>
        <ClientOpinionCard name="Marek Jonda" place="Zawadzkie">
          {formatMessage({ id: "Opinion3" })}
        </ClientOpinionCard>
        <ClientOpinionCard
          name="Ewa Hordyniak"
          place="Stowarzyszenie Gliwickie Metamorfozy"
        >
          {formatMessage({ id: "Opinion4" })}
        </ClientOpinionCard>
        <ClientOpinionCard name="Robert Augustyniak" place="Wolbrom">
          {formatMessage({ id: "Opinion5" })}
        </ClientOpinionCard>
        <ClientOpinionCard name="Roger Rasmussen" place="Sydney, Australia">
          {formatMessage({ id: "Opinion6" })}
        </ClientOpinionCard>
      </StyledCarousel>

      <SectionTitle small style={{ marginTop: "60px" }}>
        {formatMessage({ id: "Our partners" })}
      </SectionTitle>
      <Grid
        container
        item
        xs={10}
        alignItems="center"
        justify="space-around"
        style={{ margin: "30px auto 0" }}
        className="client-logo-wrapper"
      >
        {clientsData.map(item => {
          return (
            <StyledImage
              fixed={item.imgFluid}
              className="client-logo"
              key={item.name}
            />
          )
        })}
      </Grid>

      <Gallery />
    </StyledSection>
  )
}

export default About
