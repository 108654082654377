import { gsap } from "gsap"

export const enterHero = () => {
  const fadeInFirst = document.querySelectorAll(".fade-in-first")
  const fadeInSecond = document.querySelectorAll(".fade-in-second")
  const masks = document.querySelectorAll(".mask")
  const button = document.querySelector("button.main")
  const buttonLine = document.querySelector(".button-line")
  const cloudBig = document.querySelector(".cloud-big")
  const cloudSmall = document.querySelector(".cloud-small")
  const cloudMedium = document.querySelector(".cloud-medium")
  const O = document.querySelector("span.hidden")
  const tl = gsap.timeline({ paused: true })

  tl.from(fadeInFirst, {
    duration: 1,
    transform: "translateY(50px)",
    autoAlpha: 0,
    stagger: 0.2,
    delay: 1,
  })
    .to(O, {
      duration: 0.5,
      autoAlpha: 0,
      delay: -0.7,
    })
    .from(masks, {
      transform: "translateY(50px)",
      autoAlpha: 0,
      duration: 1,
      delay: -0.7,
    })
    .from(fadeInSecond, {
      transform: "translateY(50px)",
      autoAlpha: 0,
      stagger: 0.2,
      duration: 1,
      delay: -0.7,
    })
    .from(button, {
      transform: "translateY(50px)",
      autoAlpha: 0,
      stagger: 0.2,
      duration: 1,
      delay: -0.7,
    })
    .from(buttonLine, {
      autoAlpha: 0,
      stagger: 0.2,
      duration: 1,
      delay: -0.7,
    })
    .from(cloudBig, {
      autoAlpha: 0,
      transform: "translateY(10%)",
      duration: 1,
      delay: -0.9,
    })
    .from(cloudMedium, {
      autoAlpha: 0,
      transform: "translateY(20%)",
      duration: 1,
      delay: -0.9,
    })
    .from(cloudSmall, {
      autoAlpha: 0,
      transform: "translateY(30%)",
      duration: 1,
      delay: -0.9,
    })

  return tl
}
