import React from "react"
import styled from "styled-components"
import { Grid, Typography } from "@material-ui/core"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons"
import { useIntl } from "gatsby-plugin-intl"

const CardImg = styled.img`
  filter: drop-shadow(0px 0px 17px rgba(255, 255, 255, 0.45));
  width: 140px;

  @media only screen and (min-width: 1024px) {
    width: initial;
  }
`

const StyledSubtitle = styled(Typography)`
  text-align: center;
  font-weight: 300;
  margin-bottom: 20px;
  min-height: 65px;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  width: 80%;
  @media only screen and (min-width: 1024px) {
    font-size: 0.875rem;
    width: 100%;
  }
`
const StyledIcon = styled(FontAwesomeIcon)`
  position: absolute;
  transform: translateX(0);
  opacity: 0;
  transition: all 0.15s linear;
`
const StyledAniLink = styled(AniLink)`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ color }) => (color === "secondary" ? `#445CA4` : "white")};
  padding: 12px 40px !important;
  font-weight: 600;
  text-decoration: none;
  outline: none;

  span {
    pointer-events: none;
    transition: transform 0.15s linear;
    transform: translateX(0);
    font-weight: 600;

    color: ${({ color }) => (color === "secondary" ? `#445CA4` : "white")};
  }
  svg {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-400%, -50%);
    color: ${({ color }) => (color === "secondary" ? `#445CA4` : "white")};
    path {
      color: ${({ color }) => (color === "secondary" ? `#445CA4` : "white")};
    }
  }
  ::after {
    display: none;
  }
  &:hover span {
    transform: translateX(-12px);
  }
  &:hover svg {
    transform: translate(-300%, -50%);
    opacity: 1;
  }
`

const OfferCard = props => {
  const { locale, formatMessage } = useIntl()
  const { img, title, subtitle, className, link } = props

  return (
    <Grid
      container
      item
      direction="column"
      justify="space-between"
      alignItems="center"
      xs={12}
      sm={5}
      lg={3}
      style={{ margin: "30px 10px", opacity: 0, maxWidth: "280px" }}
      className={className}
    >
      <CardImg src={img} alt={title} />
      <Typography
        variant="h5"
        style={{ margin: "20px 0", fontWeight: "700", textAlign: "center" }}
      >
        {title}
      </Typography>
      <StyledSubtitle
        component="p"
        variant="subtitle2"
        style={{
          minHeight: "100px",
        }}
      >
        {subtitle}
      </StyledSubtitle>
      <StyledAniLink
        paintDrip
        duration={1}
        hex="#252a36"
        to={`/${locale}/${link}`}
      >
        <span>{formatMessage({ id: "Learn more" })}</span>
        <StyledIcon icon={faLongArrowAltRight} />
      </StyledAniLink>
    </Grid>
  )
}

export default OfferCard
